import React from "react";
import { styled } from "@mui/material/styles";
import { Button, Box, TextField } from "@mui/material";
import { signupOrLogin } from "../api/signup_or_login";
import {
  STRIPE_BASE_URL,
  STRIPE_STARTER_URL_SLUG,
  STRIPE_STANDARD_URL_SLUG,
  STRIPE_PRO_URL_SLUG,
} from "../constants";

const FormContainer = styled(Box)({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const FormItem = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  maxWidth: "300px",
  width: "100%",
}));

const ErrorMessage = styled(Box)({
  color: "red",
  marginBottom: "10px",
});

function validatePassword(password) {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  //const hasNumbers = /\d/.test(password);
  //const hasSpecialChar = /[\^$*.\[\]{}()?\-"!@#%&/,><':;|_~`]/.test(password);

  return password.length >= minLength && hasUpperCase && hasLowerCase;
}

export function getStripeUrl(subscriptionType, email) {
  const queryParams = `prefilled_email=${encodeURIComponent(email)}`;

  if (subscriptionType === "starter") {
    return `${STRIPE_BASE_URL}${STRIPE_STARTER_URL_SLUG}?${queryParams}`;
  } else if (subscriptionType === "pro") {
    return `${STRIPE_BASE_URL}${STRIPE_PRO_URL_SLUG}?${queryParams}`;
  }

  // Default to standard
  return `${STRIPE_BASE_URL}${STRIPE_STANDARD_URL_SLUG}?${queryParams}`;
}

export default function Signup() {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const urlParams = new URLSearchParams(window.location.search);
  const subscriptionType = urlParams.get("subscription_type");

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if passwords match
    if (password !== verifyPassword) {
      setErrorMessage("Passwords do not match. Please try again.");

      setPassword(""); // Clear the password field
      setVerifyPassword(""); // Clear the verify password field
      return; // Stop the handleSubmit function if passwords do not match
    }

    // Check password complexity
    if (!validatePassword(password)) {
      setErrorMessage(
        "Password must be at least 8 characters long and include an uppercase and a lowercase letter."
      );
      return;
    }

    signupOrLogin(email, password, true)
      .then(() => {
        const stripeUrl = getStripeUrl(subscriptionType, email);
        window.location.href = stripeUrl;
      })
      .catch((error) => {
        setErrorMessage(
          error.message || "An error occurred during signup. Please try again."
        );
      });
  };

  return (
    <FormContainer
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

      <FormItem>
        <TextField
          required
          id="email-input"
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          fullWidth
        />
      </FormItem>

      <FormItem>
        <TextField
          required
          id="password-input"
          label="Password"
          type="password"
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
        />
      </FormItem>

      <FormItem>
        <TextField
          required
          id="password-verify-input"
          label="Verify Password"
          type="password"
          autoComplete="new-password"
          value={verifyPassword}
          onChange={(e) => setVerifyPassword(e.target.value)}
          fullWidth
        />
      </FormItem>

      <FormItem sx={{ display: "flex", justifyContent: "center" }}>
        <Button type="submit" variant="contained">
          Create account
        </Button>
      </FormItem>
    </FormContainer>
  );
}
