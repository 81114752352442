import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { slimWidth } from "../constants";

export const LandingPageContainerFullWidth = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "relative",
  width: "100vw",
  left: "50%",
  transform: "translateX(-50%)",
  height: "100%",
  backgroundColor: theme.palette.background.dark,
  color: theme.palette.secondary.contrastText,
  padding: "4rem",
  [theme.breakpoints.down(slimWidth)]: {
    padding: "4rem 1rem",
  },
  overflow: "hidden",
  marginBottom: "4rem",
  justifyContent: "center",
  alignItems: "center",
}));
