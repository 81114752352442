import React, { useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import {
  CardContent,
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";
import {
  MAX_CHARS_PER_EPISODE,
  MIN_CHARS_PER_EPISODE,
  ELEVEN_LABS_VOICE_NAMES,
} from "../constants";
import UserContext from "../contexts/UserContext";
import { StyledCard } from "../atoms/StyledCard";
import EnhanceTextModal from "./EnhanceTextModal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MAX_TITLE_LENGTH = 100;
const MAX_SUBTITLE_LENGTH = 200;

const StyledCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledForm = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const FormItem = styled(Box)({
  marginTop: "1rem",
  width: "100%",
});

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function AddEpisodeForm({ onSubmit, addingEpisode }) {
  const { user } = useContext(UserContext);
  const usageCount = user.account.usage_count;
  const usageLimit = user.account.usage_limit;
  const usageLeft = usageLimit - usageCount;
  const maxCharsToAdd = Math.min(usageLeft, MAX_CHARS_PER_EPISODE);

  const [title, setTitle] = React.useState("");
  const [text, setText] = React.useState("");
  const [voice_name, setVoiceName] = React.useState("en_male_1");
  const [subtitle, setSubtitle] = React.useState("");
  const [summary, setSummary] = React.useState("");
  const [enhanceTextOpen, setEnhanceTextOpen] = React.useState(false);
  const [enhancedText, setEnhancedText] = React.useState(null);
  const [expandedOptions, setExpandedOptions] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({ title, text, voice_name, subtitle, summary }); // Calling the passed function
  };

  const handleTextChange = (event) => {
    event.preventDefault();
    const numChars = event.target.value.length;

    if (usageLeft < MIN_CHARS_PER_EPISODE) {
      return;
    }
    if (numChars > maxCharsToAdd) {
      return;
    }
    setText(event.target.value);
  };

  const handleTitleChange = (e) => {
    if (e.target.value.length <= MAX_TITLE_LENGTH) {
      setTitle(e.target.value);
    }
  };

  const handleSubtitleChange = (e) => {
    if (e.target.value.length <= MAX_SUBTITLE_LENGTH) {
      setSubtitle(e.target.value);
    }
  };

  const handleExpandClick = () => {
    setExpandedOptions(!expandedOptions);
  };

  useEffect(() => {
    // Emptying form after finished waiting for episode
    // TODO: Only clear the form if the episode was successful
    if (!addingEpisode) {
      setTitle("");
      setText("");
      setSubtitle("");
      setSummary("");
    }
  }, [addingEpisode]);

  return (
    <StyledCard>
      <StyledCardContent>
        <StyledForm
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <FormItem>
            <FormControl fullWidth margin="normal">
              <InputLabel id="voice-select-label">Voice</InputLabel>
              <Select
                labelId="voice-select-label"
                id="voice-select"
                value={voice_name}
                onChange={(e) => setVoiceName(e.target.value)}
                label="Voice"
                fullWidth
              >
                {Object.entries(ELEVEN_LABS_VOICE_NAMES).map(
                  ([voiceId, voice_name]) => (
                    <MenuItem key={voiceId} value={voiceId}>
                      {voice_name}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </FormItem>
          <FormItem>
            <TextField
              required
              id="episode-title-input"
              label="Title"
              type="text"
              fullWidth
              value={title}
              onChange={handleTitleChange}
              helperText={`${title.length}/${MAX_TITLE_LENGTH}`}
            />
          </FormItem>
          <FormItem>
            <TextField
              required
              id="episode-text-input"
              label="Text script to be read"
              type="text"
              multiline
              fullWidth
              minRows={4}
              maxRows={20}
              value={text}
              onChange={handleTextChange}
              helperText={
                usageLeft > MIN_CHARS_PER_EPISODE ? (
                  <>
                    {`${text.length} of max ${maxCharsToAdd} characters`}
                    <br />
                    {`Write at least ${MIN_CHARS_PER_EPISODE} characters to create episode`}
                  </>
                ) : (
                  <>
                    {`${usageLeft} characters left this month`}
                    <br />
                    {`You need at least ${MIN_CHARS_PER_EPISODE} characters to create an episode`}
                  </>
                )
              }
            />
          </FormItem>
          <FormItem>
            <Button
              onClick={handleExpandClick}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                textTransform: "none",
                paddingTop: "0.2rem",
                paddingBottom: "0.2rem",
                paddingLeft: "1rem",
                paddingRight: "0.5rem",
                color: "text.secondary",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  flex: 1,
                  textAlign: "left",
                }}
              >
                Optional fields
              </Typography>
              <ExpandMore
                expand={expandedOptions}
                aria-expanded={expandedOptions}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </Button>
            <Collapse in={expandedOptions} timeout="auto" unmountOnExit>
              <FormItem>
                <TextField
                  id="episode-subtitle-input"
                  label="Subtitle"
                  type="text"
                  fullWidth
                  value={subtitle}
                  onChange={handleSubtitleChange}
                  helperText={`${subtitle.length}/${MAX_SUBTITLE_LENGTH}`}
                />
              </FormItem>
              <FormItem>
                <TextField
                  id="episode-summary-input"
                  label="Summary"
                  type="text"
                  multiline
                  fullWidth
                  minRows={4}
                  maxRows={20}
                  value={summary}
                  onChange={(e) => setSummary(e.target.value)}
                />
              </FormItem>
            </Collapse>
          </FormItem>
          <FormItem sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Button
              type="button"
              variant="outlined"
              onClick={() => setEnhanceTextOpen(true)}
              disabled={
                addingEpisode ||
                text.length < MIN_CHARS_PER_EPISODE ||
                enhancedText
              }
            >
              {"Enhance text"}
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={addingEpisode || text.length < MIN_CHARS_PER_EPISODE}
            >
              {"Generate audio"}
            </Button>
            <EnhanceTextModal
              inputText={text}
              enhancedText={enhancedText}
              episodeTitle={title}
              setEnhancedText={setEnhancedText}
              setText={setText}
              open={enhanceTextOpen}
              handleClose={() => setEnhanceTextOpen(false)}
            />
          </FormItem>
        </StyledForm>
      </StyledCardContent>
    </StyledCard>
  );
}
