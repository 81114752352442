import React from "react";
import { Typography, Grid2 as Grid } from "@mui/material";
import { LandingPageContainerFullWidth } from "../atoms/LandingPageContainerFullWidth";
import { landingPageContainerWideWidth } from "../constants";

const PodcastIframe = () => (
  <iframe
    title="The Podreader Podcast"
    allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
    frameborder="0"
    height="450" // From Apple instructions
    style={{
      minWidth: "290px", // From Apple instructions
      width: "100%",
      maxWidth: "100%",
      overflow: "hidden",
      borderRadius: "1rem",
    }}
    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
    src="https://embed.podcasts.apple.com/us/podcast/the-podreader-podcast/id1778503040"
  />
);

export default function ApplePodcastEmbed() {
  return (
    <LandingPageContainerFullWidth>
      <Grid
        container
        spacing={4}
        alignItems="center"
        sx={{ width: landingPageContainerWideWidth }}
      >
        <Grid size={{ xs: 12, md: 5 }}>
          <Typography
            variant="h2"
            sx={{ mb: 2, textAlign: { xs: "center", md: "right" } }}
          >
            The PodReader Podcast
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: "text.secondary.contrast",
              textAlign: { xs: "center", md: "right" },
            }}
          >
            Listen to our podcast, created with PodReader, where we explore the
            ongoing journey of developing and refining this product.
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 7 }}>
          <PodcastIframe />
        </Grid>
      </Grid>
    </LandingPageContainerFullWidth>
  );
}
