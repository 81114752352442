import React, { useState, useContext, useRef } from "react";
import { styled } from "@mui/material/styles";
import { CardContent, Box, TextField, Button, Typography } from "@mui/material";
import { StyledCard } from "../atoms/StyledCard";
import { PreviewImage } from "../atoms/PreviewImage";
import FeedContext from "../contexts/FeedContext";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { useNavigate } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";

const StyledForm = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const ImageUploadContainer = styled(Box)({
  position: "relative",
  width: "fit-content",
  margin: "auto",
  cursor: "pointer",
  "&:hover .overlay": {
    opacity: 1,
  },
  "@media (min-width: 600px)": {
    alignSelf: "flex-start",
    margin: "0",
  },
});

const ImageOverlay = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  opacity: 0.7,
  transition: "opacity 0.3s",
  borderRadius: "8px",
  color: "white",
  cursor: "pointer",
});

const HiddenInput = styled("input")({
  display: "none",
});

export default function UpdateFeedDetailsForm({ onSubmit }) {
  const { activeFeed, removeFeed } = useContext(FeedContext);
  const [name, setName] = useState(activeFeed?.name || "");
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(activeFeed?.image || null);
  const [author, setAuthor] = useState(activeFeed?.author || "");
  const [summary, setSummary] = useState(activeFeed?.summary || "");
  const [contactEmail, setContactEmail] = useState(
    activeFeed?.contact_email || ""
  );
  const navigate = useNavigate();

  const fileInputRef = useRef(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);

    // Preview the image
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Create FormData to handle both the name and image upload
    const formData = new FormData();
    if (name) {
      formData.append("name", name);
    }
    if (author) {
      formData.append("author", author);
    }
    if (image) {
      formData.append("image", image);
    }
    if (summary) {
      formData.append("summary", summary);
    }
    if (contactEmail) {
      formData.append("contact_email", contactEmail);
    }

    onSubmit(formData);
  };

  const handleDeleteFeed = (e) => {
    e.preventDefault();
    e.stopPropagation();

    removeFeed(activeFeed.uuid).then(() => {
      navigate("/feeds");
    });
  };

  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h3" gutterBottom>
          Podcast settings
        </Typography>

        <StyledForm
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <ImageUploadContainer onClick={handleImageClick}>
            <PreviewImage
              src={preview || "/path/to/placeholder-image.png"}
              alt="Click to change image"
            />
            <ImageOverlay className="overlay">
              <PhotoCameraIcon sx={{ fontSize: 40, mb: 1 }} />
              <Typography variant="body2">
                {preview ? "Change Image" : "Upload Image"}
              </Typography>
            </ImageOverlay>
            <HiddenInput
              ref={fileInputRef}
              type="file"
              onChange={handleImageChange}
              accept="image/*"
            />
          </ImageUploadContainer>

          <TextField
            id="feed-name-input"
            label="Podcast name"
            type="text"
            sx={{ mt: "1rem" }}
            value={name}
            fullWidth
            onChange={(e) => setName(e.target.value)}
          />

          <TextField
            id="feed-author-input"
            label="Author"
            type="text"
            sx={{ mt: "1rem" }}
            value={author}
            fullWidth
            onChange={(e) => setAuthor(e.target.value)}
          />

          <TextField
            id="feed-contact-email-input"
            label="Contact email"
            type="email"
            sx={{ mt: "1rem" }}
            value={contactEmail}
            fullWidth
            onChange={(e) => setContactEmail(e.target.value)}
          />

          <TextField
            id="feed-summary-input"
            label="Summary"
            multiline
            rows={4}
            sx={{ mt: "1rem" }}
            value={summary}
            fullWidth
            onChange={(e) => setSummary(e.target.value)}
          />

          <Box
            sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}
          >
            <Button
              type="button"
              variant="outlined"
              onClick={handleDeleteFeed}
              startIcon={<WarningIcon />}
              color="error"
            >
              Delete podcast
            </Button>
            <Button type="submit" variant="contained">
              Save changes
            </Button>
          </Box>
        </StyledForm>
      </CardContent>
    </StyledCard>
  );
}
