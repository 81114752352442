// Env variables
export const BASE_URL = process.env.REACT_APP_BACKEND_API_URL;

// Layout variables
export const slimWidth = 700;
export const landingPageContainerWideWidth = 1100;
export const headerHeight = 64;
export const footerHeight = 200;
export const topMargin = 24;
export const bottomMargin = 64;
export const navbarDrawerWidth = 200;
export const swapNavbarWidth = 1100;

// Forms
export const BUG_REPORT_FORM_URL =
  "https://viktor-enzell.notion.site/13fc9f71f7f980ecb0baf871787ec6f8?pvs=105";
export const FEATURE_REQUEST_FORM_URL =
  "https://viktor-enzell.notion.site/13fc9f71f7f980d38476c10fde622c95?pvs=105";
export const CONTACT_FORM_URL = "mailto:podreaderai@gmail.com";

// Other variables
export const MIN_CHARS_PER_EPISODE = 20;
export const MAX_CHARS_PER_EPISODE = 5000;

// Stripe constants
export const STRIPE_BASE_URL = "https://buy.stripe.com/";
export const STRIPE_STARTER_URL_SLUG = "dR6dRYcBZ4yo4yk145";
export const STRIPE_STANDARD_URL_SLUG = "5kA0186dB1mce8U9AC";
export const STRIPE_PRO_URL_SLUG = "7sI15c9pN4yoaWI8wz";

// ElevenLabs voice names
export const ELEVEN_LABS_VOICE_NAMES = {
  en_male_1: "🇬🇧 Male English",
  en_female_1: "🇺🇸 Female English",
  en_male_2: "🇺🇸 Male English",
  sv_male_1: "🇸🇪 Male Swedish",
};

// Pricing options
export const PRICING_OPTIONS = {
  Starter: {
    title: "Starter",
    subtitle: "For trying out the product",
    bulletPoints: [
      "9,000 characters (~9 min audio)",
      "Built in podcast hosting",
      "Publish unlimited podcast shows and episodes",
    ],
    price: 99,
  },
  Standard: {
    title: "Standard",
    subtitle: "Good option for getting started",
    bulletPoints: [
      "45,000 characters (~45 min audio)",
      "Built in podcast hosting",
      "Publish unlimited podcast shows and episodes",
    ],
    price: 399,
  },
  Pro: {
    title: "Pro",
    subtitle: "Best price per minute of audio",
    bulletPoints: [
      "90,000 characters (~90 min audio)",
      "Built in podcast hosting",
      "Publish unlimited podcast shows and episodes",
    ],
    price: 689,
  },
};
