import React, { useState } from "react";
import {
  Typography,
  IconButton,
  CardContent,
  FormControlLabel,
  Switch,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import { StyledCard } from "../atoms/StyledCard";
import { ELEVEN_LABS_VOICE_NAMES } from "../constants";

const MAX_TEXT_LENGTH = 150;

const AudioPlayerContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "1rem",
});

const StyledAudio = styled("audio")({
  flexGrow: 1,
  marginRight: "16px",
  height: "30px",
});

const ExpandToggleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  cursor: "pointer",
  marginTop: "0.5rem",
  display: "block",

  "&:hover": {
    textDecoration: "underline",
  },
}));

const EpisodeTextBackground = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.secondary,
  padding: "1rem",
  borderRadius: "8px",
}));

function EpisodeTextContainer({ episode, isExpanded, setIsExpanded }) {
  return (
    <>
      <EpisodeTextBackground>
        <Typography
          variant="body2"
          sx={{ marginBottom: "0.5rem", fontWeight: "bold" }}
        >
          Podcast text:
        </Typography>

        <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
          {episode.text.length > MAX_TEXT_LENGTH ? (
            <>
              {isExpanded
                ? episode.text
                : `${episode.text.substring(0, MAX_TEXT_LENGTH)}...`}
            </>
          ) : (
            episode.text
          )}
          {episode.text.length > MAX_TEXT_LENGTH && (
            <>
              <br />
              <ExpandToggleText
                variant="body2"
                component="span"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {isExpanded ? "Show less" : "Show more"}
              </ExpandToggleText>
            </>
          )}
        </Typography>
      </EpisodeTextBackground>
    </>
  );
}

export default function ExistingEpisode({
  episode,
  onDelete,
  deletingEpisodeUuid,
  handleUpdateEpisode,
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <StyledCard
      sx={{
        opacity: deletingEpisodeUuid === episode.uuid ? 0.5 : 1,
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h5">{episode.title}</Typography>
            {episode.subtitle && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ marginBottom: "1rem" }}
              >
                {episode.subtitle}
              </Typography>
            )}
          </Box>
          <FormControlLabel
            control={
              <Switch
                checked={episode.status === "PUBLISHED"}
                onChange={(event) => handleUpdateEpisode(episode, event)}
                color={episode.status === "PUBLISHED" ? "success" : "default"}
              />
            }
            label={episode.status === "PUBLISHED" ? "Published" : "Publish"}
            labelPlacement="start"
          />
        </Box>
        <Typography variant="body2" sx={{ marginBottom: "1rem" }}>
          {new Date(episode.created_date).toLocaleDateString()} -{" "}
          {ELEVEN_LABS_VOICE_NAMES[episode.voice_name]}
        </Typography>
        {episode.summary && (
          <EpisodeTextBackground sx={{ marginBottom: "1rem" }}>
            <Typography
              variant="body2"
              sx={{ marginBottom: "0.5rem", fontWeight: "bold" }}
            >
              Summary:
            </Typography>
            <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
              {episode.summary}
            </Typography>
          </EpisodeTextBackground>
        )}
        <EpisodeTextContainer
          episode={episode}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
        />
        <AudioPlayerContainer>
          {episode.audio_url && (
            <StyledAudio controls>
              <source src={episode.audio_url} type="audio/mpeg" />
              Your browser does not support the audio element.
            </StyledAudio>
          )}
          <IconButton
            sx={{ p: 1 }}
            onClick={() => onDelete(episode.uuid)}
            size="small"
            aria-label="delete"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </AudioPlayerContainer>
      </CardContent>
    </StyledCard>
  );
}
