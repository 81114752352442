import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { headerHeight, slimWidth } from "../constants";

export const ModalContentContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 2rem)",
  height: `70%`,
  maxHeight: `calc(100% - ${headerHeight}px - 2rem)`,
  maxWidth: slimWidth,
  borderStyle: "solid",
  borderColor: theme.palette.divider,
  borderWidth: "1px",
  boxShadow: "none",
  backgroundColor: theme.palette.background.default,
  borderRadius: "0.3rem",
  padding: "1rem",
  boxSizing: "border-box",
  overflow: "hidden",
}));
