import React, { useContext } from "react";
import { Typography, Button } from "@mui/material";
import { logout } from "../api/signup_or_login";
import UserContext from "../contexts/UserContext";
import UsageProgressBar from "../components/UsageProgressBar";
import AccountPricingOptions from "../components/AccountPricingOptions";

export default function AccountDetails() {
  const { user } = useContext(UserContext);

  return (
    <>
      <Typography variant="h3" sx={{ marginLeft: 0, marginBottom: "1rem" }}>
        Logged in as {user.email}.
      </Typography>
      <UsageProgressBar />
      <AccountPricingOptions />
      <Button variant="outlined" onClick={logout}>
        Log out
      </Button>
    </>
  );
}
