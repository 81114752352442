import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import PricingOptions from "./PricingOptions.js";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { slimWidth } from "../constants";
import ApplePodcastEmbed from "./ApplePodcastEmbed.js";

export default function LandingPageContent() {
  const [currentWord, setCurrentWord] = useState("newsletter");
  const [isTransitioning, setIsTransitioning] = useState(false);
  const rotatingWords = ["newsletters", "articles", "blog posts"];
  const { width } = useWindowDimensions();

  useEffect(() => {
    let currentIndex = 0;

    const interval = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        currentIndex = (currentIndex + 1) % rotatingWords.length;
        setCurrentWord(rotatingWords[currentIndex]);
        setIsTransitioning(false);
      }, 400);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Typography variant="h1" sx={{ marginTop: "3rem", marginBottom: "1rem" }}>
        {width > slimWidth ? (
          <>
            {"Expand reach for your "}
            <span
              style={{
                opacity: isTransitioning ? 0 : 1,
                transition: "opacity 0.4s ease-in-out",
                display: "inline-block",
              }}
            >
              {currentWord}
            </span>
            <br />
            {" with automated podcasts"}
          </>
        ) : (
          <>Expand reach for your text content with automated podcasts</>
        )}
      </Typography>
      <Typography variant="subtitle1" sx={{ marginBottom: "4rem" }}>
        Transform any text into hosted podcast episodes with natural-sounding AI
        voices.
      </Typography>

      <ApplePodcastEmbed />

      <PricingOptions />
    </div>
  );
}
