import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import {
  ListItemText,
  ListItem,
  IconButton,
  Divider,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  headerHeight,
  slimWidth,
  navbarDrawerWidth,
  swapNavbarWidth,
} from "../constants";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { isLoggedIn } from "../api/signup_or_login";
import UserContext from "../contexts/UserContext";
import FeedContext from "../contexts/FeedContext";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SettingsIcon from "@mui/icons-material/Settings";

const toolBarMaxWidth = slimWidth - 48;

const Root = styled("div")({
  height: headerHeight,
});

const StyledAppBar = styled(AppBar)({
  backgroundColor: "white",
  display: "flex",
  alignItems: "center",
  boxShadow: "none",
  zIndex: 9999,
});

const StyledToolbar = styled(Toolbar)({
  maxWidth: toolBarMaxWidth,
  width: "100%",
  justifyContent: "flex-end",
  height: headerHeight,
  paddingLeft: 0,
  paddingRight: 0,

  "@media (min-width: 0px)": {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  "@media (min-width: 710px)": {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

const StyledDrawer = styled(Drawer)({
  width: navbarDrawerWidth,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: navbarDrawerWidth,
    paddingTop: headerHeight,
  },
});

const StyledLink = styled(Link)({
  textDecoration: "none",
  color: "black",
});

const SubMenuItem = styled(ListItem)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: grey[100],
  "&:hover": {
    backgroundColor: grey[200],
  },
});

const MenuItem = styled(ListItem)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "&:hover": {
    backgroundColor: grey[200],
  },
});

const AppBarDivider = styled(Divider)({
  width: "100%",
});

function MenuItems({ menuItems, setOpen, handleAddFeed }) {
  const { pathname } = useLocation();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const activeButtonColor = grey[300];

  const handleGoToFeedSettings = (e, uuid) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/feed/${uuid}/settings`);
  };

  return (
    <List sx={{ paddingTop: 0 }}>
      {menuItems.map((item) =>
        item.submenu ? (
          <StyledLink to={item.key} key={item.key}>
            <SubMenuItem
              key={item.key}
              onClick={() => {
                setOpen(false);
              }}
              sx={{
                backgroundColor:
                  pathname.includes(item.key) && activeButtonColor,
                paddingLeft: "2rem",
              }}
            >
              <ListItemText primary={item.name} />
              {item.uuid && (
                <IconButton
                  edge="end"
                  aria-label="settings"
                  onClick={(e) => handleGoToFeedSettings(e, item.uuid)}
                  disabled={!user?.account?.is_verified}
                >
                  <SettingsIcon color="primary" sx={{ fontSize: "medium" }} />
                </IconButton>
              )}
            </SubMenuItem>
          </StyledLink>
        ) : item.new_podcast ? (
          <ListItem
            key={item.key}
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              paddingLeft: "2rem",
              paddingRight: "1rem",
              backgroundColor: grey[100],
            }}
          >
            <Button
              variant="outlined"
              size="small"
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleAddFeed}
              sx={{ margin: 0, width: "100%", backgroundColor: "white" }}
              disabled={!user?.account?.is_verified}
            >
              {item.name}
            </Button>
          </ListItem>
        ) : (
          <StyledLink to={item.key} key={item.key}>
            <MenuItem
              key={item.key}
              onClick={() => {
                setOpen(false);
              }}
              sx={{
                backgroundColor:
                  pathname.includes(item.key) && activeButtonColor,
              }}
            >
              <ListItemText primary={item.name} />
            </MenuItem>
          </StyledLink>
        )
      )}
    </List>
  );
}

function DesktopNavbarLoggedIn({ menuItems, handleAddFeed }) {
  return (
    <Root>
      <StyledAppBar position="fixed">
        <StyledToolbar>
          <Typography variant="h3" sx={{ flexGrow: 1 }}>
            <StyledLink to="/feeds">PodReader</StyledLink>
          </Typography>
        </StyledToolbar>
        <AppBarDivider />
      </StyledAppBar>
      <StyledDrawer variant="permanent" anchor="left" open={true}>
        <MenuItems
          menuItems={menuItems}
          setOpen={() => {}}
          handleAddFeed={handleAddFeed}
        />
      </StyledDrawer>
    </Root>
  );
}

function MobileNavbarLoggedIn({ menuItems, handleAddFeed }) {
  const [open, setOpen] = React.useState(false);

  return (
    <Root>
      <StyledAppBar position="fixed">
        <StyledToolbar>
          <Typography variant="h3" sx={{ flexGrow: 1 }}>
            <StyledLink to="/feeds">PodReader</StyledLink>
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(!open)}
          >
            <MenuIcon sx={{ color: "black" }} />
          </IconButton>
        </StyledToolbar>
        <AppBarDivider />
      </StyledAppBar>
      <StyledDrawer
        variant="temporary"
        anchor="right"
        open={open}
        ModalProps={{ onBackdropClick: () => setOpen(false) }}
      >
        <MenuItems
          menuItems={menuItems}
          setOpen={setOpen}
          handleAddFeed={handleAddFeed}
        />
      </StyledDrawer>
    </Root>
  );
}

function NavbarLoggedOut({ menuItems }) {
  return (
    <Root>
      <StyledAppBar position="fixed">
        <StyledToolbar>
          <Typography variant="h3" sx={{ flexGrow: 1 }}>
            <StyledLink to="/">PodReader</StyledLink>
          </Typography>
          {menuItems.map((item) => (
            <Button
              key={item.key}
              href={item.key}
              variant={item.variant}
              size="small"
              color={item.color}
              sx={{ marginLeft: "1rem" }}
            >
              {item.name}
            </Button>
          ))}
        </StyledToolbar>
        <AppBarDivider />
      </StyledAppBar>
    </Root>
  );
}

export default function Navbar() {
  const { width } = useWindowDimensions();
  const { user, userLoading } = useContext(UserContext);
  const { feeds, newFeed, switchFeed } = useContext(FeedContext);
  const navigate = useNavigate();

  const handleAddFeed = (e) => {
    e.preventDefault();
    e.stopPropagation();
    newFeed().then((createdFeed) => {
      switchFeed(createdFeed.uuid);
      navigate(`/feed/${createdFeed.uuid}`);
    });
  };

  const loggedInMenuItems = [
    { key: "/feeds", name: "Podcasts" },
    ...feeds.map((feed) => ({
      key: `/feed/${feed.uuid}`,
      name: feed.name,
      uuid: feed.uuid,
      submenu: true,
    })),
    { key: "/feed/new", name: "New podcast", new_podcast: true },
    { key: "/account", name: "Account" },
  ];
  const loggedOutMenuItems = [
    { key: "/login", name: "Login", variant: "outlined" },
    {
      key: "/login?signup=true&subscription_type=standard",
      name: "Get PodReader",
      variant: "contained",
    },
  ];
  const menuItems = isLoggedIn(user) ? loggedInMenuItems : loggedOutMenuItems;

  return (
    <div>
      {userLoading ? (
        <DesktopNavbarLoggedIn menuItems={[]} handleAddFeed={handleAddFeed} />
      ) : isLoggedIn(user) ? (
        width > swapNavbarWidth ? (
          <DesktopNavbarLoggedIn
            menuItems={menuItems}
            handleAddFeed={handleAddFeed}
          />
        ) : (
          <MobileNavbarLoggedIn
            menuItems={menuItems}
            handleAddFeed={handleAddFeed}
          />
        )
      ) : (
        <NavbarLoggedOut menuItems={menuItems} handleAddFeed={handleAddFeed} />
      )}
    </div>
  );
}
