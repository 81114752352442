import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import PricingOption from "./PricingOption";
import { PRICING_OPTIONS } from "../constants";

export default function PricingOptions() {
  return (
    <Box>
      <Typography variant="h2">Pricing</Typography>
      <Typography variant="subtitle2" sx={{ mb: "1.2rem" }}>
        Simple, transparent pricing: only pay for the characters you convert to
        audio. No subscriptions, no commitments, no hidden fees.
      </Typography>

      <Grid container spacing={3}>
        <Grid item sm={4} xs={12}>
          <PricingOption
            title={PRICING_OPTIONS.Starter.title}
            subtitle={PRICING_OPTIONS.Starter.subtitle}
            bulletPoints={PRICING_OPTIONS.Starter.bulletPoints}
            price={PRICING_OPTIONS.Starter.price}
            button={{
              href: "/login?signup=true&subscription_type=starter",
              text: "Get PodReader",
            }}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <PricingOption
            title={PRICING_OPTIONS.Standard.title}
            subtitle={PRICING_OPTIONS.Standard.subtitle}
            bulletPoints={PRICING_OPTIONS.Standard.bulletPoints}
            price={PRICING_OPTIONS.Standard.price}
            button={{
              href: "/login?signup=true&subscription_type=standard",
              text: "Get PodReader",
            }}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <PricingOption
            title={PRICING_OPTIONS.Pro.title}
            subtitle={PRICING_OPTIONS.Pro.subtitle}
            bulletPoints={PRICING_OPTIONS.Pro.bulletPoints}
            price={PRICING_OPTIONS.Pro.price}
            button={{
              href: "/login?signup=true&subscription_type=pro",
              text: "Get PodReader",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
