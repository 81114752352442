import React, { useEffect, useContext } from "react";
import { Modal, Typography, Button, Box, Skeleton } from "@mui/material";
import { ModalContentContainer } from "../atoms/ModalContentContainer";
import { enhanceText } from "../api/text_enhancement";
import UserContext from "../contexts/UserContext";
import FeedContext from "../contexts/FeedContext";
import theme from "../theme";

export default function EnhanceTextModal({
  inputText,
  enhancedText,
  episodeTitle,
  setEnhancedText,
  setText,
  open,
  handleClose,
}) {
  const { loadUser } = useContext(UserContext);
  const { activeFeed } = useContext(FeedContext);

  useEffect(() => {
    if (open && !enhancedText) {
      enhanceText(inputText, activeFeed.uuid, episodeTitle)
        .then((result) => {
          const { enhanced_text } = result;
          setEnhancedText(enhanced_text);
          loadUser();
        })
        .catch((error) => {
          setEnhancedText("ERROR");
          loadUser();
          handleClose();
        });
    }
  }, [
    open,
    inputText,
    enhancedText,
    setEnhancedText,
    handleClose,
    loadUser,
    activeFeed,
    episodeTitle,
  ]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContentContainer>
        <Typography id="modal-modal-title" variant="h3" component="h2">
          Suggested text modifications
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{
            mt: "1rem",
            height: "100%",
            width: "100%",
            overflowY: "auto",
            padding: "1rem",
            backgroundColor: theme.palette.background.secondary,
            borderRadius: "8px",
            whiteSpace: "pre-wrap",
          }}
        >
          {!enhancedText ? (
            <>
              <Skeleton variant="text" width="100%" height={30} />
              <Skeleton variant="text" width="100%" height={30} />
              <Skeleton variant="text" width="100%" height={30} />
              <Skeleton variant="text" width="100%" height={30} />
              <Skeleton variant="text" width="70%" height={30} />
            </>
          ) : (
            enhancedText
          )}
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}>
          <Button type="button" variant="outlined" onClick={handleClose}>
            {"Cancel modifications"}
          </Button>
          <Button
            type="button"
            variant="contained"
            disabled={!enhancedText || enhancedText === "ERROR"}
            onClick={() => {
              setText(enhancedText);
              handleClose();
            }}
          >
            {"Apply modifications"}
          </Button>
        </Box>
      </ModalContentContainer>
    </Modal>
  );
}
