import React, { useState, useEffect } from "react";
import FeedContext from "./FeedContext";
import {
  getFeeds,
  updateFeedDetails,
  createFeed,
  deleteFeed,
} from "../api/feed";

export const FeedProvider = ({ children }) => {
  const [feeds, setFeeds] = useState([]);
  const [activeFeedUuid, setActiveFeedUuid] = useState(null);
  const [feedLoading, setFeedLoading] = useState(true);

  const updateFeed = (formData) => {
    updateFeedDetails(activeFeedUuid, formData)
      .then((updatedFeed) => {
        setFeeds((prevFeeds) =>
          prevFeeds.map((feed) =>
            feed.uuid === activeFeedUuid ? updatedFeed : feed
          )
        );
      })
      .catch((error) => {
        console.error("Error updating feed:", error);
      });
  };

  const loadFeeds = () => {
    getFeeds()
      .then((feedsData) => {
        setFeeds(feedsData);
        if (feedsData.length > 0) {
          setActiveFeedUuid(feedsData[0].uuid);
        }
      })
      .catch((error) => {
        console.error("Error loading feeds:", error);
      })
      .finally(() => {
        setFeedLoading(false);
      });
  };

  const switchFeed = (uuid) => {
    if (feeds.some((feed) => feed.uuid === uuid)) {
      setActiveFeedUuid(uuid);
    }
  };

  const newFeed = () => {
    return createFeed()
      .then((newFeed) => {
        setFeeds((prevFeeds) => [...prevFeeds, newFeed]);
        return newFeed;
      })
      .catch((error) => {
        console.error("Error creating feed:", error);
      });
  };

  const removeFeed = (uuid) => {
    return deleteFeed(uuid)
      .then(() => {
        setFeeds((prevFeeds) => prevFeeds.filter((feed) => feed.uuid !== uuid));
      })
      .catch((error) => {
        console.error("Error deleting feed:", error);
      });
  };

  useEffect(() => {
    loadFeeds();
  }, []);

  const activeFeed = feeds.find((feed) => feed.uuid === activeFeedUuid) || null;

  return (
    <FeedContext.Provider
      value={{
        feeds,
        activeFeed,
        activeFeedUuid,
        feedLoading,
        loadFeeds,
        updateFeed,
        switchFeed,
        newFeed,
        removeFeed,
      }}
    >
      {children}
    </FeedContext.Provider>
  );
};
