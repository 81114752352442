import React, { useState, useEffect, useContext } from "react";
import AddEpisodeForm from "./AddEpisodeForm";
import {
  listEpisodes,
  addEpisode,
  deleteEpisode,
  updateEpisode,
} from "../api/episode";
import {
  CardContent,
  Typography,
  Skeleton,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import FeedContext from "../contexts/FeedContext";
import UserContext from "../contexts/UserContext";
import { StyledCard } from "../atoms/StyledCard";
import ExistingEpisode from "./ExistingEpisode";

export default function EpisodeList() {
  const { loadUser } = useContext(UserContext);
  const { activeFeed } = useContext(FeedContext);
  const [episodes, setEpisodes] = useState([]);
  const [addingEpisode, setAddingEpisode] = useState(false);
  const [deletingEpisodeUuid, setDeletingEpisodeUuid] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchEpisodes = () => {
    if (episodes.length === 0) {
      // Show loading skeleton while fetching episodes
      setAddingEpisode(true);
    }
    listEpisodes(activeFeed.uuid)
      .then((responseData) => {
        setEpisodes(responseData);
        setAddingEpisode(false);
        setDeletingEpisodeUuid(null);
      })
      .catch((error) => {
        setError(true);
        setErrorMessage(error.message || "Failed to fetch episodes.");
        setAddingEpisode(false);
        setDeletingEpisodeUuid(null);
      });
  };

  const handleDelete = (episode_uuid) => {
    setDeletingEpisodeUuid(episode_uuid);
    deleteEpisode(activeFeed.uuid, episode_uuid)
      .then(() => {
        fetchEpisodes();
      })
      .catch((error) => {
        setError(true);
        setErrorMessage(error.message || "Failed to delete episode.");
      });
  };

  const handleSubmitEpisode = async (formData) => {
    setAddingEpisode(true);
    addEpisode(activeFeed.uuid, formData)
      .then((data) => {
        fetchEpisodes();
        loadUser();
      })
      .catch((error) => {
        setError(true);
        setErrorMessage(error.message || "Failed to add episode.");
        fetchEpisodes();
        loadUser();
      });
  };

  const handleUpdateEpisode = (episode, event) => {
    const newStatus = event.target.checked ? "PUBLISHED" : "DRAFT";

    updateEpisode(activeFeed.uuid, episode.uuid, {
      status: newStatus,
    })
      .then(() => {
        fetchEpisodes();
      })
      .catch((error) => {
        setError(true);
        setErrorMessage(error.message || "Failed to update episode.");
        fetchEpisodes();
      });
  };

  const handleCloseError = () => {
    setError(false);
    setErrorMessage("");
  };

  useEffect(() => {
    if (activeFeed === null) {
      return;
    }
    fetchEpisodes();
  }, [activeFeed]);

  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h3" sx={{ mb: 2 }} gutterBottom>
          Episodes
        </Typography>
        <Box sx={{ mb: 2 }}>
          <AddEpisodeForm
            onSubmit={handleSubmitEpisode}
            addingEpisode={addingEpisode}
          />
        </Box>
        {addingEpisode && (
          <Box sx={{ mb: 2 }}>
            <StyledCard>
              <CardContent>
                <Skeleton variant="text" width={210} height={30} />
                <Skeleton variant="text" width="100%" height={30} />
                <Skeleton variant="text" width="100%" height={30} />
                <Skeleton variant="text" width="70%" height={30} />
              </CardContent>
            </StyledCard>
          </Box>
        )}
        {episodes.map((episode) => (
          <Box sx={{ mb: 2 }} key={episode.uuid}>
            <ExistingEpisode
              episode={episode}
              onDelete={handleDelete}
              deletingEpisodeUuid={deletingEpisodeUuid}
              handleUpdateEpisode={handleUpdateEpisode}
            />
          </Box>
        ))}
      </CardContent>
      <Snackbar open={error} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error">
          {errorMessage} Please try again.
        </Alert>
      </Snackbar>
    </StyledCard>
  );
}
