import React from "react";
import {
  Button,
  Typography,
  CardContent,
  CardActions,
  Box,
} from "@mui/material";
import { StyledCard } from "../atoms/StyledCard";

export default function PricingOption({
  title,
  subtitle,
  bulletPoints,
  button,
  price,
}) {
  return (
    <StyledCard sx={{ height: "100%" }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h3" component="h2" sx={{ mb: 1 }}>
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            height: 35,
            mb: 1,
          }}
        >
          {subtitle}
        </Typography>
        <Box component="ul" sx={{ pl: "1rem" }}>
          {bulletPoints.map((point) => (
            <li key={point}>
              <Typography variant="body2">{point}</Typography>
            </li>
          ))}
        </Box>
      </CardContent>

      <Box sx={{ mx: 1, mb: 0.5 }}>
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          {price} kr
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            fontSize: "0.6rem",
          }}
        >
          (One time purchase)
        </Typography>
      </Box>

      <CardActions sx={{ justifyContent: "center", pb: "1rem" }}>
        <Button variant="contained" size="small" href={button.href}>
          {button.text}
        </Button>
      </CardActions>
    </StyledCard>
  );
}
